import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector";
import { addNotification, removeNotificaionCategory } from "Redux/slices/appSlice";
export var useIncreaseNotification = function () {
    var dispatch = useDispatch();
    var _a = useAppSelector(function (state) { return state.app; }), notifications = _a.notifications, discountIncrease = _a.discountIncrease;
    var _b = useAppSelector(function (state) { return state.userInfo; }), user = _b.user, isUserTypeDefined = _b.isUserTypeDefined;
    var isNeedShown = useMemo(function () {
        var currentNotification = notifications
            ? notifications.find(function (notification) { return notification.category === "discountIncrease"; })
            : undefined;
        var lastTimeStamp = localStorage.getItem("lastTimeStampOfDiscountIncrease");
        var currentTimeStamp = new Date().toISOString().split("T")[0];
        var isNewNotice = discountIncrease && !currentNotification && !lastTimeStamp;
        var isNoticeNotShownToday = (discountIncrease && lastTimeStamp && new Date(currentTimeStamp) > new Date(lastTimeStamp)) || false;
        if (isNewNotice || isNoticeNotShownToday)
            return true;
        return false;
    }, [discountIncrease, notifications]);
    var isNeedUpdate = useMemo(function () {
        var currentNotification = notifications
            ? notifications.find(function (notification) { return notification.category === "discountIncrease"; })
            : undefined;
        if ((currentNotification === null || currentNotification === void 0 ? void 0 : currentNotification.value[0]) && discountIncrease) {
            var _a = currentNotification === null || currentNotification === void 0 ? void 0 : currentNotification.value[0].content, percent = _a.percent, dateTo = _a.dateTo;
            return percent !== discountIncrease.percent || dateTo !== discountIncrease.dateTo;
        }
        return false;
    }, [discountIncrease, notifications]);
    useEffect(function () {
        if (discountIncrease === undefined || !isUserTypeDefined)
            return;
        if (discountIncrease === null || (user === null || user === void 0 ? void 0 : user.discountIncrease) === null) {
            dispatch(removeNotificaionCategory("discountIncrease"));
            localStorage.removeItem("lastTimeStampOfDiscountIncrease");
        }
        function showNotice() {
            dispatch(removeNotificaionCategory("discountIncrease"));
            var isCatalogUrl = ["/categories", "/brands", "/catalog", "/specials"].some(function (url) {
                return location.pathname.startsWith(url);
            });
            !isCatalogUrl &&
                dispatch(addNotification({
                    category: "discountIncrease",
                    id: Date.now().toString(),
                    content: discountIncrease,
                }));
            localStorage.removeItem("lastTimeStampOfDiscountIncrease");
        }
        isNeedUpdate ? showNotice() : isNeedShown ? setTimeout(showNotice, 10000) : null;
    }, [isUserTypeDefined, discountIncrease]);
};
