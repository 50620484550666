import { saveCookie } from "Utils/saveCookie";
import localStorage from "Utils/localStorage";
import { hostName } from "Static/variables";
export var saveUtmLabels = function (user, labels) {
    saveCookie("utmLabels", labels.utmLabels, { domain: ".".concat(hostName), "max-age": 86400 });
    if (!user) {
        var antecedentLabels = localStorage.getItem("utmLabels");
        var actualLabels = antecedentLabels ? JSON.parse(antecedentLabels) : [];
        actualLabels.push(labels);
        localStorage.setItem("utmLabels", JSON.stringify(actualLabels));
    }
};
