import { initGTM } from "Utils/initGTM";
import { isBrowser } from "Utils/isBrowser";
var isBrowserEnv = isBrowser();
export var isDevMode = process.env.NODE_ENV === "development";
export var hostName = "ls.net.ru";
export var cdnHost = "https://cdn.".concat(hostName);
export var siteHost = process.env.SITE_HOST ? process.env.SITE_HOST : "https://".concat(isDevMode ? "dev." : "").concat(hostName);
export var crmUrl = siteHost + "/crm";
export var adminHost = process.env.ADMIN_HOST
    ? process.env.ADMIN_HOST
    : "https://".concat(isDevMode ? "dev." : "").concat(hostName);
export var apiUrl = process.env.API_URL
    ? process.env.API_URL
    : isDevMode
        ? "https://api2.dev.".concat(hostName, "/apix/v2")
        : "https://api2.".concat(hostName, "/apix/v2");
export var botsApiUrl = process.env.API_BOTS_URL ? process.env.API_BOTS_URL : apiUrl;
export var dataLayer = initGTM();
export var _tmr = isBrowserEnv ? window._tmr || [] : [];
export var mailRu_TrackerId = isDevMode ? "3209890" : "3263248";
export var WA_number = "79107912931";
export var YMerchantId = "d3e95488-a7ad-4854-a1fa-a76e098bfd22";
