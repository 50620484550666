var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { dataLayer } from "Static/variables";
export var addImpressionsGTM = function (_a) {
    var productsList = _a.productsList, listInfo = _a.listInfo;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            impressions: productsList.map(function (product, idx) { return ({
                brand: product.brand.name,
                category: product.category.name,
                id: product.id,
                list: listInfo,
                name: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""),
                position: idx + 1,
                price: product.actual_price,
                variant: product.sku,
            }); }),
        },
    });
};
export var addToCartGTM = function (_a) {
    var product = _a.product, actionFieldList = _a.actionFieldList, quantity = _a.quantity;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            add: {
                actionField: { list: actionFieldList },
                products: [
                    {
                        brand: product.brand.name,
                        category: product.category.name,
                        id: product.id,
                        name: product.model + (product.model_full ? " ".concat(product.model_full) : ""),
                        price: product.actual_price,
                        quantity: quantity,
                        variant: product.sku,
                    },
                ],
            },
        },
    });
};
export var removeFromCartGTM = function (_a) {
    var productsList = _a.productsList, quantity = _a.quantity;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            remove: {
                products: productsList.map(function (product) { return ({
                    name: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""),
                    id: product.id,
                    price: product.actual_price,
                    brand: product.brand.name,
                    category: product.category.name,
                    variant: product.sku,
                    quantity: quantity,
                }); }),
            },
        },
    });
};
export var addToWishlistGTM = function (_a) {
    var _b, _c;
    var product = _a.product, category = _a.category;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "add_to_wishlist",
        ecommerce: {
            currency: "RUB",
            items: [
                __assign(__assign({ item_id: product.sku, item_name: product.model + (product.model_full ? " ".concat(product.model_full) : ""), item_brand: ((_b = product === null || product === void 0 ? void 0 : product.brand) === null || _b === void 0 ? void 0 : _b.name) || typeof product.barcode === "string" ? product.brand : "", item_category: (_c = product === null || product === void 0 ? void 0 : product.category) === null || _c === void 0 ? void 0 : _c.name }, (category && { item_list_name: "Catalog page - ".concat(category) })), { item_variant: product.barcode, price: product.actual_price, quantity: 1 }),
            ],
        },
    });
};
export var checkoutInfoGTM = function (_a) {
    var productsList = _a.productsList, cartData = _a.cartData;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            checkout: {
                actionField: { step: "1", option: "cart" },
                products: productsList.map(function (product) {
                    var _a;
                    return {
                        name: product.model,
                        id: product.id,
                        price: product.actual_price,
                        brand: product.brand.name,
                        category: product.category.name,
                        variant: product.sku,
                        quantity: ((_a = cartData.find(function (productInCart) { return productInCart.productId === product.id; })) === null || _a === void 0 ? void 0 : _a.sizes.length) || 1,
                    };
                }),
            },
        },
        "gtm-ee-event-category": "Enhanced Ecommerce",
        "gtm-ee-event-action": "Checkout Step 1",
        "gtm-ee-event-non-interaction": false,
    });
};
export var productClickGTM = function (_a) {
    var list = _a.list, productsForSend = _a.productsForSend, fullProductsList = _a.fullProductsList;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        currencyCode: "RUB",
        ecommerce: {
            click: {
                actionField: { list: list },
                products: productsForSend.map(function (product, idx) {
                    var _a;
                    return ({
                        brand: ((_a = product.brand) === null || _a === void 0 ? void 0 : _a.name) || product.brand,
                        category: product.category_name || product.category.name,
                        id: product.id,
                        name: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""),
                        position: fullProductsList.findIndex(function (currentProduct) { return currentProduct.id === product.id; }) + 1 || idx + 1,
                        price: product.actual_price,
                        variant: product.sku,
                    });
                }),
            },
        },
    });
};
export var productDetailGTM = function (_a) {
    var product = _a.product;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            detail: {
                products: [
                    {
                        brand: product.brand.name,
                        category: product.category.name,
                        id: product.id,
                        name: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""),
                        price: product.actual_price,
                        variant: product.sku,
                    },
                ],
            },
        },
    });
};
