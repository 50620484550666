var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./FavouritesHeart.scss";
import React, { useEffect, useState } from "react";
import { addProductToCartOrFavourites, removeProductFromCartOrFavourites } from "Redux/slices/cartSlice";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { addToWishlistGTM } from "Utils/analyticEvents";
import { HeartIcon } from "Svg/HeartIcon";
export var FavouritesHeart = function (_a) {
    var className = _a.className, _b = _a.category, category = _b === void 0 ? "" : _b, product = _a.product, onClick = _a.onClick;
    var dispatch = useAppDispatch();
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    var productsInFavourites = useAppSelector(function (state) { return state.cart; }).productsInFavourites;
    var _c = __read(useState([]), 2), favouritesIds = _c[0], setFavouritesIds = _c[1];
    useEffect(function () {
        setFavouritesIds(productsInFavourites.map(function (product) { return product.productId; }));
    }, [productsInFavourites]);
    var addToFavourites = function (event, product) {
        event.stopPropagation();
        var isRemove = favouritesIds.includes(product.id);
        if (isRemove) {
            dispatch(removeProductFromCartOrFavourites({
                removedProducts: [product.id],
                location: "favourites",
                needSyncWithDB: !!user,
            }));
        }
        else {
            dispatch(addProductToCartOrFavourites({
                newProducts: [{ productId: product.id, sizes: [] }],
                location: "favourites",
                needSyncWithDB: !!user,
            }));
            addToWishlistGTM({ product: product, category: category });
        }
        onClick && onClick();
    };
    return (React.createElement("div", { className: "favouritesHeart" + (className ? " ".concat(className) : ""), onClick: function (e) { return addToFavourites(e, product); } },
        React.createElement(HeartIcon, { pathStyle: {
                stroke: favouritesIds.includes(product.id) ? "var(--blue-500)" : "",
                fill: favouritesIds.includes(product.id) ? "var(--blue-500)" : "",
            } })));
};
